import L from 'leaflet';
import Alpine from 'alpinejs';

window.addEventListener('load', () => {
    var map = L.map('m4721', {
        center: [50.60383, 12.64329],
        zoom: 16
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap'
    }).addTo(map);

    var marker = L.marker([50.60383, 12.64329]).addTo(map);

    marker.bindPopup("<b>Praxis für Logopädie</b><br>Goethestraße 3,<br>08289 Schneeberg, Sachsen").openPopup();

    Alpine.start();
});
